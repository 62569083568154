import React from 'react';

import tab2 from 'assets/images/tabs/tab2.png';

const ProjectTabSection2 = () => {
  return (
    <>
      <div className='tab-wrapper'>
        <div className='img-wrapper'>
          <img data-src={tab2} className='lazyload' alt='tab2' />
          <div className='content'>
            <p>
              When we become peaceful, nature becomes peaceful. When there is unrest, it reflects in nature, too. It is
              a direct reflection.
            </p>
            <span>- Daaji</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection2;
