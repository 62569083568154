import React from 'react';

import tab4 from 'assets/images/tabs/tab4.png';

const ProjectTabSection4 = () => {
  return (
    <>
      <div className='tab-wrapper padding50'>
        <div className='img-wrapper'>
          <img data-src={tab4} className='lazyload' alt='tab4' />
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection4;
