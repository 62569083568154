import React from 'react';

import indiaMap from 'assets/images/icons/indiamap.svg';

import leaf from 'assets/images/icons/leaf.svg';

// data

import { nurseries } from 'assets/data/project';

const ProjectTabSection3 = ({ stateCarousel, mapCurrentTab }) => {
  const changeMapSlider = (ev) => {
    const currentSlide = parseInt(ev.currentTarget.id) + 1;
    stateCarousel.current.goToSlide(currentSlide);
  };

  return (
    <div>
      <div className='tab-wrapper tab-carousel'>
        <div className='state-locator'>
          <div className='sl-img-wrapper'>
            <img data-src={indiaMap} className='lazyload' alt='indiaMap' />
            <div className='location-indicators'>
              {nurseries.location.map((item) => {
                return (
                  <div
                    className={`li-img ${item.id === mapCurrentTab ? 'active' : ''}`}
                    style={{
                      left: `${item.x}%`,
                      top: `${item.y}%`,
                    }}
                    key={item.id}
                    title={item.name}
                    id={item.id}
                    onClick={(ev) => {
                      changeMapSlider(ev);
                    }}>
                    <img src={leaf} alt='leaf' />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTabSection3;
