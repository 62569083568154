import React from 'react';

import tab1 from 'assets/images/tabs/tab8.jpg';

const ProjectTabSection8 = () => {
  return (
    <>
      <div className='tab-wrapper' >
        <div className='img-wrapper'>
          <img data-src={tab1} className='lazyload' alt='tab1' />
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection8;
