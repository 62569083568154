import React, { useState, useEffect } from 'react';

import { SRLWrapper } from 'simple-react-lightbox';

import Carousel from 'react-multi-carousel';

import { Link } from "gatsby";

import "react-multi-carousel/lib/styles.css";

import DonationService from "services/donations/donation.service";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export default function RecentPlantations() {
  const [apiGallareyImages, setApiGallareyImages] = useState([]);

  const ds = new DonationService();

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="section-title">
          <h3 className="text-start">
            Recent <span className="green">plantations</span>
          </h3>
        </div>
        <div className="arrow-navigations">
          <button onClick={() => previous()}>
            <i className="icon icon-arrow-left-short"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-arrow-right-short"></i>
          </button>
        </div>
      </div>
    );
  };

  const getPublicPlantationImages = () => {
    const inputs = {
      pageNumber: 0,
      pageSize: 10,
      recentPlantationListFilter: {
        status: "ACTIVE",
      },
      sortingFieldName: "CREATED_DATE",
      sortingOrder: "ASCENDING",
    };
    ds.getPublicRecentPlantation(inputs)
      .then((res) => {
        setApiGallareyImages(
          res.data.recentPlantation?.filter((item) => {
            return item?.imageResource;
          })
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    getPublicPlantationImages();
  }, []);

  const ApiGallery = apiGallareyImages?.map((item) => {
    return {
      ...item,
      imageResource: item?.imageResource?.[0],
    };
  });

  const result = [].concat
    .apply([], ApiGallery)
    .map((item) => {
      return {
        order: item.order,
        title: item.title,
        description: item.description,
        imageId: item.imageResource.id,
        img: item.imageResource.imageUrl,
        status: item.status,
        plantationId: item.id,
      };
    })
    .sort(function (a, b) {
      return a.order - b.order;
    });

  return (
    <div className="recent-plantation-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="carousel-section">
                <SRLWrapper>
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={true}
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ButtonGroup />}
                  >
                    {result?.map((item) => {
                      return (
                        <div className="img-wrapper" key={item.id}>
                          <img
                            data-src={item.img}
                            className="lazyload"
                            alt={item.description}
                          />
                          <div style={{ textAlign: "center" }}>
                            <span>{item.description}</span>
                          </div>
                          <div className="overlay">
                            <i className="icon icon-arrows-fullscreen"></i>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </SRLWrapper>
              </div>
              <div className="text-center mt-5">
                <Link to="/gallery">
                  <button className="btn btn-secondary gallery-view-all">
                    VIEW ALL
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
