import bs from 'assets/images/press-release/business-standard.png';
import dc from 'assets/images/press-release/deccan.png';
import ht from 'assets/images/press-release/hindustan-times.png';
import apn from 'assets/images/press-release/apn.png';
import th from 'assets/images/press-release/the-hindu.png';
import toi from 'assets/images/press-release/the-times-of-india.png';
// import tt from 'assets/images/press-release/tt.png';
import zee5 from 'assets/images/press-release/zee5.png';
// import amritv from 'assets/images/press-release/amritv.png';
// import siasat from 'assets/images/press-release/siasat.png';
import timesnownews from 'assets/images/press-release/timesnownews.png';
// import twitter from 'assets/images/press-release/twitter.png';
// import nrinews from 'assets/images/press-release/nrinews.png';
import newindian from 'assets/images/press-release/newindian-logo.png';

export const pressReleaseData = [
  // {
  //   id: 1,
  //   img: nrinews,
  //   alt: 'nrinews',
  //   title: 'Heartfulness And IOCL TAPSO Launch A Greenery Hub',
  //   desc: 'HYDERABAD: Indian Oil Corporation Ltd (IOCL) in association with Heartfulness Institute inaugurated a mini greenery hub at the new environment-friendly facility of Telangana & Andhra Pradesh State Office (TAPSO) at Indian Oil Bhavan, Sanath Nagar',
  //   link: 'https://nrinews24x7.com/heartfulness-and-iocl-tapso-launch-a-greenery-hub-this-indian-republic-day/',
  // },
  {
    id: 1,
    img: th,
    alt: 'bosch',
    title: 'Bosch to raise a mini-forest across Coimbatore, Bengaluru and Hyderabad',
    desc: 'COIMBATORE Bosch Global Software Technologies (BGSW), formerly known as Robert Bosch Engineering and Business Solutions (RBEI), has partnered with Forests by Heartfulness to plant 25,000 saplings in a mini-forest plantation drive across Coimbatore, Bengaluru and Hyderabad..',
    link: 'https://www.thehindu.com/news/cities/Coimbatore/bosch-to-raise-a-mini-forest-across-coimbatore-bengaluru-and-hyderabad/article65193231.ece',
  },

  {
    id: 2,
    img: newindian,
    alt: 'union agriculture',
    title: 'Union Agriculture Minister Narendra Tomar lauds tree culture lab at Kanha',
    desc: 'Visiting the Tissue Culture Lab at Kanha Shanti Vanam in Rangareddy district, Tomar said he realized that endangered tree species could be saved and developed besides creating employment..',
    link: 'https://www.newindianexpress.com/states/telangana/2021/dec/30/tomar-lauds-tree-culture-lab-at-kanha-2401143.html',
  },
  {
    id: 3,
    img: apn,
    alt: 'forest urban',
    title: 'Forest by Heartfulness Partners with Zscaler on Urban Forest India',
    desc: 'Forest by Heartfulness is partnering with Zscaler™, the leader in cloud security, to plant 15,000 trees in an urban forest project. The plantation drive will benefit local communities in India where Zscaler has operations, including Hyderabad, Bangalore, Pune and Mohali..',
    link: 'https://www.apnnews.com/forest-by-heartfulness-partners-with-zscaler-on-urban-forest-india/',
  },

  {
    id: 4,
    img: toi,
    alt: 'timesofindia',
    title: 'IOC sets up mini greenery hub in city',
    desc: 'Hyderabad: Indian Oil Corporation Ltd (IOCL) in association with Heartfulness Institute inaugurated a mini greenery hub at the new environment-friendly facility of Telangana & Andhra Pradesh state office at Indian Oil Bhavan, Sanath Nagar',
    link: 'https://timesofindia.indiatimes.com/city/hyderabad/ioc-sets-up-mini-greenery-hub-in-city/articleshow/89144345.cms',
  },
  {
    id: 5,
    img: zee5,
    alt: 'Zee5',
    title: 'हार्टफुलनेस वृक्ष संरक्षण केंद्र में Tissue Culture Laboratory का Narendra Tomar ने किया शुभारंभ',
    desc: 'नई दिल्ली: केंद्रीय कृषि एवं किसान कल्याण मंत्री श्री नरेंद्र सिंह तोमर ने हैदराबाद में कान्हा शांतिवनम का अवलोकन किया। इस दौरान उन्होंने हार्टफुलनेस वृक्ष संरक्षण केंद्र द्वारा देशभर में लुप्तप्रायः पेड़-पौधों की प्रजातियों के प्रसार में मदद के लिए स्थापित टिश्यू कल्चर लेबोरेटरी का शुभारंभ किया।  तोमर ने कहा कि कान्हा शांति वनम अध्यात्मका',
    link: 'https://www.zee5.com/articles/narendra-tomar-launches-tissue-culture-laboratory-at-heartfulness-tree-conservation-center',
  },
  // {
  //   id: 2,
  //   img: amritv,
  //   alt: 'amritvichar',
  //   title: 'हार्टफुलनेस वृक्ष संरक्षण केंद्र में टिश्यू कल्चर लेबोरेटरी का तोमर ने किया शुभारंभ',
  //   desc: 'हैदराबाद, नई दिल्ली। कृषि मंत्री नरेंद्र सिंह तोमर ने हार्टफुलनेस वृक्ष संरक्षण केंद्र द्वारा देशभर में लुप्तप्रायः पेड़-पौधों की प्रजातियों के प्रसार में मदद के लिए स्थापित टिश्यू कल्चर लेबोरेटरी का हैदराबाद में शुभारंभ किया। तोमर ने इस अवसर पर कहा कि कान्हा शांतिवनम अध्यात्म का भी केंद्र है, जो मानव जीवन के विकास और निरंतरता के लिए बहुत आवश्यक है।',
  //   link: 'https://amritvichar.com/tomar-inaugurated-tissue-culture-laboratory-at-heartfulness-tree-conservation-center/',
  // },
  // {
  //   id: 3,
  //   img: siasat,
  //   alt: 'siasat',
  //   title: 'Tissue culture lab for endangered plant species in Hyderabad',
  //   desc: 'Hyderabad: A modern tissue culture laboratory to help propagate critically endangered plant and tree species in the country has come up at Kanha Shantivanam on the outskirts of Hyderabad.',
  //   link: 'https://www.siasat.com/tissue-culture-lab-for-endangered-plant-species-in-hyderabad-2249877/',
  // },
  {
    id: 6,
    img: timesnownews,
    alt: 'timesnownews',
    title: 'Agriculture minister Narendra Tomar inaugurates Heartfulness ..',
    desc: 'Union Minister Narendra Singh Tomar inaugurated “Heartfulness Tree Conservation Centre”, a modern tissue culture laboratory to save critically endangered tree species.',
    link: 'https://www.timesnownews.com/india/article/agriculture-minister-narendra-tomar-inaugurates-heartfulness-tree-conservation-centre/844822',
  },
  // {
  //   id: 5,
  //   img: twitter,
  //   alt: 'twitter',
  //   title:
  //     'A modern tissue culture laboratory to help propagate critically endangered plant and tree species in the country ',
  //   desc: 'A modern tissue culture laboratory to help propagate critically endangered plant and tree species in the country has come up at Kanha Shantivanam on the outskirts of #Hyderabad.',
  //   link: 'https://twitter.com/ians_india/status/1476171936765865984',
  // },
  {
    id: 7,
    img: toi,
    alt: 'timesofindia',
    title: 'Union agri min bats for endangered plants',
    desc: 'Hyderabad: Union minister for agriculture & farmers’ welfare Narendra Singh Tomar said new ideas should be introduced to save endangered plant species.',
    link: 'https://timesofindia.indiatimes.com/city/hyderabad/union-agri-min-bats-for-endangered-plants/articleshow/88575962.cms',
  },
  {
    id: 8,
    img: dc,
    alt: 'deccanchronicle',
    title: 'Telangana: Green drive – 18 crore more saplings planted than before',
    desc: ' Hyderabad: The third phase of Haritha Haram which began on July 12 has seen the planting of over 34.7 crore saplings across the..',
    link: 'https://www.deccanchronicle.com/lifestyle/pets-and-environment/261217/telangana-green-drive-18-crore-more-saplings-planted-than-before.html',
  },
  {
    id: 9,
    img: ht,
    alt: 'hindustantimes',
    title: 'CRPF Pune subscribes to city’s ‘go green’ plan',
    desc: 'On Wednesday, top CRPF officials led by Birender Kumar Topo, DIG participated in the Hindustan Times’s Go Green tree plantation.',
    link: 'https://www.hindustantimes.com/pune-news/crpf-pune-subscribes-to-city-s-go-green-plan/story-zrj8xKYmfqY7tO4B4acnTJ.html',
  },
  {
    id: 10,
    img: apn,
    alt: 'apnnews',
    title: 'Coinciding with World Environment UNEP Recognizes Shri Ram Chandra Mission',
    desc: 'Mumbai:  United Nations Environment Program (UNEP), under its Faith for Earth and United Religions Initiative (URI), has recognized the Shri Ram Chandra Mission..',
    link: 'https://www.apnnews.com/coinciding-with-world-environment-unep-recognizes-shri-ram-chandra-mission-heartfulness-institute-for-its-contribution-to-the-climate-action/',
  },
  {
    id: 11,
    img: th,
    alt: 'the hindu',
    title: 'Celebrating India’s Olympics feat, with a difference',
    desc: 'The Heartfulness Institute of Hyderabad organised ‘Forests by Heartfulness’, a nation-wide initiative to plant seven lakh saplings to mark the seven medals won won by India in Tokyo Games..',
    link: 'https://www.thehindu.com/news/cities/Hyderabad/celebrating-indias-olympics-feat-with-a-difference/article35868849.ece',
  },
  {
    id: 12,
    img: toi,
    alt: 'timesofindia',
    title: 'Over 300 trees uprooted for road widening get new lease of life in Kanha Shantivanam',
    desc: 'HYDERABAD: More than 300 trees that were marked for felling in different parts of Hyderabad and adjoining areas in order to facilitate road widening works..',
    link: 'https://timesofindia.indiatimes.com/city/hyderabad/over-300-trees-uprooted-for-road-widening-get-new-lease-of-life-in-kanha-shantivanam/articleshow/60717887.cms',
  },
  {
    id: 13,
    img: ht,
    alt: 'hindustantimes',
    title: 'A meditation drive with green cover initiatives in mind',
    desc: 'The meditation and tree plantation session was organised with the objective to create awareness for a mass movement to plant trees and take responsibility to make Pune a green smart city..',
    link: 'https://www.hindustantimes.com/pune-news/a-meditation-drive-with-green-cover-initiatives-in-mind/story-lbngNnZoAjGexdr2ExabRL.html',
  },
  {
    id: 14,
    img: bs,
    alt: 'business-standard',
    title: 'Heartfulness Institute to develop rainforest near Hyderabad',
    desc: 'The Heartfulness Institute on Monday announced plans to develop a mini-rainforest over 25 acres of land at Kanha Shantivanam, its global headquarters, on the outskirts of Hyderabad..',
    link: 'https://www.business-standard.com/article/news-ians/heartfulness-institute-to-develop-rainforest-near-hyderabad-118060400389_1.html',
  },
  // {
  //   id: 12,
  //   img: tt,
  //   alt: 'telanganatoday',
  //   title: 'Here are the noteworthy events took place last week across India',
  //   desc: 'WWF India’s Hyderabad Office announced its initiative with Heartfulness Institute to adopt 50,000 trees at Kanha Shantivanam, the global headquarters of Heartfulness, in Hyderabad..',
  //   link: 'https://telanganatoday.com/here-are-the-noteworthy-events-took-place-last-week-across-india',
  // },
  {
    id: 15,
    img: apn,
    alt: 'apnnews',
    title:
      'WWF India and Sri Ram Chandra Mission Foundation (Heartfulness Institute) join hands to adopt 50,000 trees in Hyderabad',
    desc: 'This initiative is part of WWF India’s Sustainable Trees program and the Green Kanha initiative at Heartfulness, both committed to planting and nurturing trees..',
    link: 'https://www.apnnews.com/wwf-india-and-sri-ram-chandra-mission-foundation-heartfulness-institute-join-hands-to-adopt-50000-trees-in-hyderabad/',
  },
];
