import BGSW from 'assets/images/partner/1.png';
import Volkswagen from 'assets/images/partner/2.png';
import GovernmentofTelangana from 'assets/images/partner/3.png';
import GovernmentofMadhyaPradesh from 'assets/images/partner/4.png';
import Accenture from 'assets/images/partner/5.png';
import Google from 'assets/images/partner/6.png';
import BankofAmerica from 'assets/images/partner/7.png';
import WWF from 'assets/images/partner/8.png';
import Zscaler from 'assets/images/partner/9.png';
import DeccanChemicals from 'assets/images/partner/10.png';
import PersistentSyatems from 'assets/images/partner/12.png';
import ThoughtSpot from 'assets/images/partner/13.png';
import IndianOil from 'assets/images/partner/11.png';

export const partnerCarousel = [

  {
    id: 1,
    img: BGSW,
    alt: '',
    class:'partner-logo-items20',
  },
  {
    id: 2,
    img: Volkswagen,
    alt: '',
  },
  {
    id: 3,
    img: GovernmentofTelangana,
    alt: '',
  },

  {
    id: 4,
    img: GovernmentofMadhyaPradesh,
    alt: '',
  },
  {
    id: 5,
    img: Accenture,
    alt: '',
    class:'partner-logo-items',
  },
  {
    id: 6,
    img: Google,
    alt: '',
    class:'partner-logo-items',
  },
  {
    id: 7,
    img: BankofAmerica,
    alt: '',
    class:'partner-logo-items',
  },
  {
    id: 8,
    img: WWF,
    alt: '',
  },
  {
    id: 9,
    img: Zscaler,
    alt: '',
    class:'partner-logo-items',
  },
  {
    id: 10,
    img: DeccanChemicals,
    alt: '',
    class:'partner-logo-items',
  },  
  {
    id: 11,
    img: IndianOil,
    alt: '',
    class:'partner-logo-items20',
  },
  {
    id: 12,
    img: PersistentSyatems,
    alt: '',
    class:'partner-logo-items',
  },
  {
    id: 13,
    img: ThoughtSpot,
    alt: '',
    class:'partner-logo-items',
  },
];
