import BangaloreImg from "assets/images/nurseries/Bangalore Nursery Volunteers.jpg";
import CoimbatoreImg from "assets/images/nurseries/Coimbatore Nursery Primary Bed preparation.jpg";
import DelhiImg from "assets/images/nurseries/Delhi Nursery Seedling preparation.jpg";
import IndoreImg from "assets/images/nurseries/Indore Nursery Volunteer Team.jpg";
import JaipurImg from "assets/images/nurseries/Jaipur Nursery Soil bags filled.jpg";
import KangeyamImg from "assets/images/nurseries/Kangeyam Nursery Seedling production with children.jpg";
import KanhaImg from "assets/images/nurseries/Kanha Nursery Drone Image.jpg";
import KolakaluruImg from "assets/images/nurseries/Kolakaluru Nursery Soil Bag filling.jpg";
import LucknowImg from "assets/images/nurseries/Lucknow Nursery Soil cover filling.jpg";
import MumbaiImg from "assets/images/nurseries/Mumbai Nursery staff removing weeds.jpg";
import RaipurImg from "assets/images/nurseries/Raipur Soil Covers.jpg";
import RajahmundryImg from "assets/images/nurseries/Rajahmundry Nursery Soil Bed preparation.jpg";
import SiliguriImg from "assets/images/nurseries/Siliguri Nursery.jpg";
import ThiruvallurImg from "assets/images/nurseries/Thiruvallur Nursery Seedling Production Team.jpg";
import TiruppurImg from "assets/images/nurseries/Tiruppur Nursery Cover Filling.jpg";
import TrivandrumImg from "assets/images/nurseries/Trivandrum Nursery Cover Filling.jpg";
import VadodaraImg from "assets/images/nurseries/Vadodara Nursery Seed Sowing.jpg";
import VishakapatnamImg from "assets/images/nurseries/Vishakapatnam Nursery Sapling care.jpg";

export const nurseries = {
  tabName: "Forests By Heartfulness Network of 18 nurseries",
  carousel: [
    {
      id: 1,
      img: BangaloreImg,
      alt: "Bangalore Nursery Volunteers",
      locationName: "Bangalore Nursery Volunteers",
    },
    {
      id: 2,
      img: CoimbatoreImg,
      alt: "Coimbatore Nursery Primary Bed preparation",
      locationName: "Coimbatore Nursery Primary Bed preparation",
    },
    {
      id: 3,
      img: DelhiImg,
      alt: "Delhi Nursery Seedling preparation",
      locationName: "Delhi Nursery Seedling preparation",
    },
    {
      id: 4,
      img: IndoreImg,
      alt: "Indore Nursery Volunteer Team",
      locationName: "Indore Nursery Volunteer Team",
    },
    {
      id: 5,
      img: JaipurImg,
      alt: "Jaipur Nursery Soil bags filled",
      locationName: "Jaipur Nursery Soil bags filled",
    },
    {
      id: 6,
      img: KangeyamImg,
      alt: "Kangeyam Nursery Seedling production with children",
      locationName: "Kangeyam Nursery Seedling production with children",
    },
    {
      id: 7,
      img: KanhaImg,
      alt: "Kanha Nursery Drone Image",
      locationName: "Kanha Nursery Drone Image",
    },
    {
      id: 8,
      img: KolakaluruImg,
      alt: "Kolakaluru Nursery Soil Bag filling",
      locationName: "Kolakaluru Nursery Soil Bag filling",
    },
    {
      id: 9,
      img: LucknowImg,
      alt: "Lucknow Nursery Soil cover filling",
      locationName: "Lucknow Nursery Soil cover filling",
    },
    {
      id: 10,
      img: MumbaiImg,
      alt: "Mumbai Nursery staff removing weeds",
      locationName: "Mumbai Nursery staff removing weeds",
    },
    {
      id: 11,
      img: RaipurImg,
      alt: "Raipur Soil Covers",
      locationName: "Raipur Soil Covers",
    },
    {
      id: 12,
      img: RajahmundryImg,
      alt: "Rajahmundry Nursery Soil Bed preparation",
      locationName: "Rajahmundry Nursery Soil Bed preparation",
    },
    {
      id: 13,
      img: SiliguriImg,
      alt: "Siliguri Nursery",
      locationName: "Siliguri Nursery",
    },
    {
      id: 14,
      img: ThiruvallurImg,
      alt: "Thiruvallur Nursery Seedling Production Team",
      locationName: "Thiruvallur Nursery Seedling Production Team",
    },
    {
      id: 15,
      img: TiruppurImg,
      alt: "Tiruppur Nursery Cover Filling",
      locationName: "Tiruppur Nursery Cover Filling",
    },
    {
      id: 16,
      img: TrivandrumImg,
      alt: "Trivandrum Nursery Cover Filling",
      locationName: "Trivandrum Nursery Cover Filling",
    },
    {
      id: 17,
      img: VadodaraImg,
      alt: "Vadodara Nursery Seed Sowing",
      locationName: "Vadodara Nursery Seed Sowing",
    },
    {
      id: 18,
      img: VishakapatnamImg,
      alt: "Vishakapatnam Nursery Sapling care",
      locationName: "Vishakapatnam Nursery Sapling care",
    },
  ],
  location: [
    {
      id: 1,
      name: "Bangalore",
      x: 31,
      y: 80.2,
    },
    {
      id: 2,
      name: "Coimbatore",
      x: 28,
      y: 89,
    },
    {
      id: 3,
      name: "Delhi",
      x: 30.5,
      y: 28,
    },
    {
      id: 4,
      name: "Indore",
      x: 26,
      y: 49,
    },
    {
      id: 5,
      name: "Jaipur",
      x: 26,
      y: 34,
    },
    {
      id: 6,
      name: "Kangeyam",
      x: 34,
      y: 89,
    },
    {
      id: 7,
      name: "Kanha",
      x: 37,
      y: 65,
    },
    {
      id: 8,
      name: "Kolakaluru",
      x: 42,
      y: 70,
    },
    {
      id: 9,
      name: "Lucknow",
      x: 44,
      y: 35,
    },
    {
      id: 10,
      name: "Mumbai",
      x: 14.8,
      y: 62,
    },
    {
      id: 11,
      name: "Raipur",
      x: 46.4,
      y: 55.2,
    },
    {
      id: 12,
      name: "Rajahmundry",
      x: 46,
      y: 68,
    },
    {
      id: 13,
      name: "Siliguri",
      x: 71.5,
      y: 35,
    },
    {
      id: 14,
      name: "Thiruvallur",
      x: 39.5,
      y: 81,
    },
    {
      id: 15,
      name: "Tiruppur",
      x: 30,
      y: 86,
    },
    {
      id: 16,
      name: "Trivandrum",
      x: 29,
      y: 97,
    },
    {
      id: 17,
      name: "Vadodara",
      x: 16,
      y: 49,
    },
    {
      id: 18,
      name: "Vishakapatnam",
      x: 53,
      y: 63.5,
    },
  ],
};
